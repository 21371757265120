import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Settings from './pages/Settings';
import NFeDocs from './pages/NFeDocs';
import Inutilizacao from './pages/Inutilizacao';
import NotFound from './pages/NotFound';

const Routers: React.FC = () => {
  return (
    <Routes>
      <Route path="/settings/:token" element={<Settings />} />
      <Route path="/nfe/:token" element={<NFeDocs />} />
      <Route path="/inutilizacao/:token" element={<Inutilizacao />} />
      {/* <Route path="/" element={<Home />} /> */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Routers;
