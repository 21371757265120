import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
`;

export const Container = styled.div`
  background: #fff;
  padding: 15px;
  border-radius: 4px;
  position: relative;
  max-height: 90%;
  overflow-y: ${(props) => (props.autoOverflow ? 'auto' : 'visible')};
`;

export const ExitButton = styled.button`
  border: 0;
  background: none;
  position: absolute;
  background-color: #333;
  display: flex;
  height: 36px;
  width: 36px;
  border-radius: 50px;
  left: -18px;
  top: -18px;

  svg {
    height: 100%;
    width: 100%;
  }
`;
