/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { formatRFC3339, format } from 'date-fns';
import ApiService from '@services/api';
import INFe from '@interfaces/INFe';
import { MdDownload, MdRemoveRedEye } from 'react-icons/md';

import { downloadFile } from '@utils/download';
import Loading from '@components/Loading';
import RoundButton from '@components/RoundButton';
import Modal from '@components/Modal';
import NotAuthenticated from '@components/NotAuthenticated';
import SelectInput from '@components/SelectInput';
import Button from '@components/Button';
import Paginator from '@components/Paginator';

import { useParams } from 'react-router-dom';
import {
  Container,
  Title,
  Selection,
  Buttons,
  DateInput,
  Table,
  ContainerStatus,
  Status,
  Actions,
} from './styles';

const currencyFormat = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const modeloOptions = [
  { label: 'Todos', value: 1 },
  { label: '55 (NF-e)', value: 55 },
  { label: '65 (NFC-e)', value: 65 },
];

const NFeDocs: React.FC = () => {
  const params = useParams();
  const { api } = new ApiService(params.token);

  const [dataIniSel, setDataIniSel] = useState(
    formatRFC3339(new Date()).substr(0, 10)
  );
  const [dataFimSel, setDataFimSel] = useState(
    formatRFC3339(new Date()).substr(0, 10)
  );
  const [modeloSel, setModeloSel] = useState(modeloOptions[0]);
  const [loading, setLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [rejeicao, setRejeicao] = useState('');
  const [notas, setNotas] = useState<INFe[]>([]);
  const [page, setPage] = useState(1);
  const [totalNotas, setTotalNotas] = useState(100);
  const [queryCount, setQueryCount] = useState(0);

  useEffect(() => {
    loadNotas();
  }, [page]);

  async function loadNotas() {
    try {
      setLoading(true);

      const queryParams: any = {
        page,
        startDate: dataIniSel,
        endDate: dataFimSel,
      };
      if (modeloSel.value !== 1) {
        queryParams.model = modeloSel.value;
      }

      const resp = await api.get('/nfe/docs', {
        params: queryParams,
      });
      setNotas(resp.data.items);
      setTotalNotas(resp.data.total);
      setQueryCount(resp.data.queryCount);
      setAuthenticated(true);
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        setAuthenticated(false);
      } else {
        toast.error('Falha ao carregar');
      }
    } finally {
      setLoading(false);
    }
  }

  async function handleDownload(nota: INFe) {
    try {
      const resp = await api.get(`/nfe/docs/${nota.id}/xml`, {
        responseType: 'blob',
      });
      if (nota.statusId === 101) {
        const dh = format(new Date(), 'yyyyMMdd-HHmmss');

        downloadFile(resp.data, `xml-${dh}.zip`);
      } else {
        downloadFile(
          resp.data,
          `${nota.protocol}_v${nota.version}-procNFe.xml`
        );
      }
      // const urlObj = window.URL.createObjectURL(resp.data);
      // window.open(urlObj, '_blank')?.focus();
    } catch (error: any) {
      if (error?.response?.status === 404) {
        toast.error('Download indisponível');
      } else {
        toast.error('Falha ao realizar o download');
      }
    }
  }

  async function handleDownloadAll() {
    toast.success(
      'Download solicitado. Aguarde que ele será iniciado automaticamente.'
    );
    try {
      const queryParams: any = {
        startDate: dataIniSel,
        endDate: dataFimSel,
      };
      if (modeloSel.value !== 1) {
        queryParams.model = modeloSel.value;
      }

      const resp = await api.get(`/nfe/docs/xml`, {
        params: queryParams,
      });
      window.open(resp.data.url);
    } catch (error: any) {
      if (error?.response?.data?.error) {
        toast.error(`Processo abortado! ${error?.response?.data?.error}`);
      } else {
        toast.error('Processo abortado! Falha ao realizar o download');
      }
    }
  }

  function renderEmissao(nota: INFe) {
    return (
      <ContainerStatus>
        {format(new Date(nota.issueDate), 'dd/MM/yyyy HH:mm')}
        {nota.contingencyType && (
          <Status style={{ background: '#0071FF' }}>
            {nota.contingencyType === 9 && 'Contingência Offline'}
            {nota.contingencyType === 4 && 'Contingência EPEC'}
          </Status>
        )}
      </ContainerStatus>
    );
  }

  function renderStatus(cStat: number, xStat: string) {
    if (cStat === 100)
      return <Status style={{ background: '#007506' }}>Autorizada</Status>;
    if (cStat === 101)
      return <Status style={{ background: '#080808' }}>Cancelada</Status>;
    if (cStat === 110)
      return <Status style={{ background: '#c26a05' }}>Denegada</Status>;
    if (cStat > 200)
      return (
        <>
          <Status style={{ background: '#c70d0d' }}>Rejeitada</Status>{' '}
          <RoundButton
            hint="Visualizar Rejeição"
            onClick={() => setRejeicao(xStat)}
          >
            <MdRemoveRedEye size={18} />
          </RoundButton>
        </>
      );
    return xStat;
  }

  if (loading)
    return (
      <Container>
        <Loading />
      </Container>
    );

  if (!authenticated) {
    return <NotAuthenticated />;
  }

  return (
    <Container>
      <Title>NOTAS FISCAIS</Title>
      <Selection>
        <label>
          Emissão
          <div>
            <DateInput
              value={dataIniSel}
              onChange={(e: any) => setDataIniSel(e.target.value)}
            />{' '}
            a{' '}
            <DateInput
              value={dataFimSel}
              onChange={(e: any) => setDataFimSel(e.target.value)}
            />
          </div>
        </label>
        <label>
          Modelo
          <SelectInput
            fullWidth
            options={modeloOptions}
            defaultValue={modeloOptions[0]}
            value={modeloSel}
            onChange={(newValue: any) => setModeloSel(newValue)}
          />
        </label>

        <Buttons>
          <Button
            onClick={() => {
              setPage(1);
              loadNotas();
            }}
          >
            Consultar
          </Button>
          <Button onClick={() => handleDownloadAll()}>Download XML</Button>
        </Buttons>
      </Selection>

      {notas.length > 0 && (
        <>
          <Table>
            <thead>
              <tr>
                <th style={{ width: '10%' }}>Número</th>
                <th style={{ width: '10%' }}>Série</th>
                <th style={{ width: '5%' }}>Modelo</th>
                <th style={{ width: '20%' }}>Emissão</th>
                <th style={{ width: '20%' }}>Destinatário</th>
                <th style={{ width: '10%' }}>Valor</th>
                <th style={{ width: '20%' }}>Status</th>
                <th style={{ width: '5%' }}>Ações</th>
              </tr>
            </thead>
            <tbody>
              {notas.map((nota) => (
                <tr key={nota.id}>
                  <td>{nota.number}</td>
                  <td>{nota.serie}</td>
                  <td>{nota.model}</td>
                  <td>{renderEmissao(nota)}</td>
                  <td>{nota?.receiver?.name}</td>
                  <td>{nota.total ? currencyFormat.format(nota.total) : ''}</td>
                  <td>
                    <ContainerStatus>
                      {nota.receiptDate &&
                        format(new Date(nota.receiptDate), 'dd/MM/yyyy HH:mm')}
                      {renderStatus(nota.statusId, nota.status)}
                    </ContainerStatus>
                  </td>
                  <td>
                    <Actions>
                      <RoundButton
                        hint="Download"
                        onClick={() => handleDownload(nota)}
                      >
                        <MdDownload size={18} />
                      </RoundButton>
                      {/* <RoundButton hint="Imprimir">
                    <MdPrint size={18} />
                  </RoundButton> */}
                    </Actions>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div>
            Exibindo {page > 1 ? (page - 1) * 20 + 1 : 1} a{' '}
            {page > 1 ? (page - 1) * 20 + queryCount : queryCount} de um total
            de {totalNotas} notas.
          </div>
        </>
      )}

      <Paginator
        activePage={Number(page)}
        limitPage={20}
        totalRecords={totalNotas}
        onChange={(newPage) => setPage(newPage)}
      />

      {rejeicao && (
        <Modal onCloseRequest={() => setRejeicao('')} exitButton={false}>
          <div style={{ padding: 40 }}>{rejeicao}</div>
        </Modal>
      )}
    </Container>
  );
};

export default NFeDocs;
