import styled from 'styled-components';
import Button from '@components/Button';
import colors from '@styles/colors';

export const Container = styled.div`
  padding: 10px;
  width: 100%;
`;

export const Title = styled.div`
  color: ${colors.primary};
  margin: 5px 0 10px 5px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #333;

  span {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    margin-top: 30px;
  }

  label {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    margin-left: 5px;

    & > :first-child {
      margin-top: 3px;
    }
  }
`;

export const Input = styled.input`
  height: 38px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0 10px;

  &:focus {
    border: 2px solid ${colors.primary};
  }
`;

export const ApplyButton = styled(Button)`
  width: 150px;
`;
