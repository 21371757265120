/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import ApiService from '@services/api';
import IInutilizacao from '@interfaces/IInutilizacao';
import { MdDownload } from 'react-icons/md';

import { downloadFile } from '@utils/download';
import Loading from '@components/Loading';
import RoundButton from '@components/RoundButton';
import NotAuthenticated from '@components/NotAuthenticated';
import SelectInput from '@components/SelectInput';
import Button from '@components/Button';
import Paginator from '@components/Paginator';

import { useParams } from 'react-router-dom';
import { Container, Title, Selection, Buttons, Table, Actions } from './styles';

const modeloOptions = [
  { label: 'Todos', value: 1 },
  { label: '55 (NF-e)', value: 55 },
  { label: '65 (NFC-e)', value: 65 },
];

const Inutilizacao: React.FC = () => {
  const params = useParams();
  const { api } = new ApiService(params.token);

  const [modeloSel, setModeloSel] = useState(modeloOptions[0]);
  const [loading, setLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [records, setRecords] = useState<IInutilizacao[]>([]);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(100);
  const [queryCount, setQueryCount] = useState(0);

  useEffect(() => {
    loadRecords();
  }, [page]);

  async function loadRecords() {
    try {
      setLoading(true);

      const queryParams: any = {
        page,
      };
      if (modeloSel.value !== 1) {
        queryParams.model = modeloSel.value;
      }

      const resp = await api.get('/inutilizacao', {
        params: queryParams,
      });
      setRecords(resp.data.items);
      setTotalRecords(resp.data.total);
      setQueryCount(resp.data.queryCount);
      setAuthenticated(true);
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        setAuthenticated(false);
      } else {
        toast.error('Falha ao carregar');
      }
    } finally {
      setLoading(false);
    }
  }

  async function handleDownload(inutilizacao: IInutilizacao) {
    try {
      const resp = await api.get(`/inutilizacao/${inutilizacao.id}/xml`, {
        responseType: 'blob',
      });

      downloadFile(
        resp.data,
        `${inutilizacao.protocol}_v${inutilizacao.version}-ProcInutNFe.xml`
      );

      // const urlObj = window.URL.createObjectURL(resp.data);
      // window.open(urlObj, '_blank')?.focus();
    } catch (error: any) {
      if (error?.response?.status === 404) {
        toast.error('Download indisponível');
      } else {
        toast.error('Falha ao realizar o download');
      }
    }
  }

  async function handleDownloadAll() {
    toast.success(
      'Download solicitado. Aguarde que ele será iniciado automaticamente.'
    );
    try {
      const queryParams: any = {};
      if (modeloSel.value !== 1) {
        queryParams.model = modeloSel.value;
      }

      const resp = await api.get(`/inutilizacao/xml`, {
        params: queryParams,
      });
      window.open(resp.data.url);
    } catch (error: any) {
      if (error?.response?.data?.error) {
        toast.error(`Processo abortado! ${error?.response?.data?.error}`);
      } else {
        toast.error('Processo abortado! Falha ao realizar o download');
      }
    }
  }

  if (loading)
    return (
      <Container>
        <Loading />
      </Container>
    );

  if (!authenticated) {
    return <NotAuthenticated />;
  }

  return (
    <Container>
      <Title>INUTILIZAÇÃO DE NUMERAÇÃO</Title>
      <Selection>
        <label>
          Modelo
          <SelectInput
            fullWidth
            options={modeloOptions}
            defaultValue={modeloOptions[0]}
            value={modeloSel}
            onChange={(newValue: any) => setModeloSel(newValue)}
          />
        </label>

        <Buttons>
          <Button
            onClick={() => {
              setPage(1);
              loadRecords();
            }}
          >
            Consultar
          </Button>
          <Button onClick={() => handleDownloadAll()}>Download XML</Button>
        </Buttons>
      </Selection>

      {records.length > 0 && (
        <>
          <Table>
            <thead>
              <tr>
                <th>Ano</th>
                <th>Modelo</th>
                <th>Série</th>
                <th>Número Inicial</th>
                <th>Número Final</th>
                <th>Data</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {records.map((record) => (
                <tr key={record.key}>
                  <td>{record.year}</td>
                  <td>{record.model}</td>
                  <td>{record.serie}</td>
                  <td>{record.numberStart}</td>
                  <td>{record.numberEnd}</td>
                  <td>
                    {format(new Date(record.receiptDate), 'dd/MM/yyyy HH:mm')}
                  </td>
                  <td>
                    <Actions>
                      <RoundButton
                        hint="Download"
                        onClick={() => handleDownload(record)}
                      >
                        <MdDownload size={18} />
                      </RoundButton>
                    </Actions>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div>
            Exibindo {page > 1 ? (page - 1) * 20 + 1 : 1} a{' '}
            {page > 1 ? (page - 1) * 20 + queryCount : queryCount} de um total
            de {totalRecords} registros.
          </div>
        </>
      )}

      <Paginator
        activePage={Number(page)}
        limitPage={20}
        totalRecords={totalRecords}
        onChange={(newPage) => setPage(newPage)}
      />
    </Container>
  );
};

export default Inutilizacao;
