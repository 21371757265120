import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px;
  background: #f5f5f5;
  padding: 40px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #333;
`;

export const Message = styled.span`
  margin-left: 10px;
  font-size: 24px;
`;
