import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 0;
  background: #ddd;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  transition: background 0.3s;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  &[disabled] {
    background: none;
    cursor: default;
  }
`;

export const Hint = styled.div`
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.visible ? '0.9' : '0')};
  transition: visibility 0s, opacity 0.3s linear;
  display: flex;
  justify-content: center;
  text-align: center;
  position: absolute;
  background: #333;
  color: #f5f5f5;
  font-size: 13px;
  z-index: 9;
  padding: 5px 10px;
  border-radius: 4px;
  top: 46px;
  width: ${(props) => props.width}px;

  ${(props) => {
    if (props.position === 'right') return 'right: 0';
    if (props.position === 'left') return 'left: 0';
    return null;
  }};

  &::before {
    content: '';
    position: absolute;
    top: -8px;
    ${(props) => {
      if (props.position === 'right') return 'right: 10px';
      if (props.position === 'left') return 'left: 10px';
      return 'left: calc(50% - 8px);';
    }};
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #333;
  }
`;
