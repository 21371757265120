/* eslint-disable react/jsx-filename-extension */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import { Container, Button, Hint } from './styles.js';

function RoundButton({ hint, children, ...rest }) {
  const [showHint, setShowHint] = useState(false);
  return (
    <Container
      onMouseOver={() => setShowHint(true)}
      onFocus={() => setShowHint(true)}
      onMouseOut={() => setShowHint(false)}
      onBlur={() => setShowHint(false)}
    >
      <Button {...rest}>{children}</Button>
      {hint && (
        <Hint visible={showHint} position={hint?.position} width={hint?.width}>
          {hint?.text || hint}
        </Hint>
      )}
    </Container>
  );
}

export default RoundButton;
