import styled from 'styled-components';
import colors from '@styles/colors';

const Input = styled.input`
  height: 38px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0 10px;

  &:focus {
    border: 2px solid ${colors.primary};
  }
`;

export default Input;
