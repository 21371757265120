import axios, { Axios } from 'axios';

class ApiService {
  public api: Axios;

  constructor(token: string) {
    this.api = axios.create({
      baseURL: `${process.env.REACT_APP_APP_URL || ''}/api`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });
  }
}

export default ApiService;
