/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { MdDone } from 'react-icons/md';
import { toast } from 'react-toastify';
import Loading from '@components/Loading';
import SelectInput from '@components/SelectInput';
import NotAuthenticated from '@components/NotAuthenticated';
import data from '@store/Settings';
import { observer } from 'mobx-react-lite';
import ApiService from '@services/api';
import { useParams } from 'react-router-dom';
import Certificate from './Certificate';

import { Container, Title, Form, ApplyButton } from './styles';

const ambienteOptions = [
  { label: 'Produção', value: 1 },
  { label: 'Homologação', value: 2 },
];

const Settings: React.FC = observer(() => {
  const params = useParams();
  const { api } = new ApiService(params?.token);

  useEffect(() => {
    loadSettings();
  }, []);

  async function loadSettings() {
    try {
      data.setLoading(true);
      const response = await api.get('/settings');
      data.setNfe(response.data.nfe);
      data.setAuthenticated(true);
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        data.setAuthenticated(false);
      } else {
        toast.error('Erro ao carregar configurações');
      }
    } finally {
      data.setLoading(false);
    }
  }

  async function handleSubmit(e: any) {
    e.preventDefault();

    try {
      data.setLoading(true);
      const formData = new FormData();
      if (data.nfe?.uploadCertificado?.file) {
        formData.append(
          'file_nfe_certificado',
          data.nfe.uploadCertificado.file
        );
      } else {
        delete data.nfe.certificado;
      }
      formData.append('data', JSON.stringify(data));
      const response = await api.put('/settings', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      data.setNfe(response.data.nfe);
      toast.success('Configurações salvas com sucesso');
    } catch (error) {
      data.setUploadCertificadoNfe(undefined, '');
      data.setRemoverCertificadoNfe(undefined);
      toast.error('Falha ao salvar as configurações');
    } finally {
      data.setLoading(false);
    }
  }

  if (data.loading) {
    return <Loading />;
  }

  if (!data.authenticated) {
    return <NotAuthenticated />;
  }

  return (
    <Container>
      <Title>CONFIGURAÇÕES</Title>

      <Form onSubmit={handleSubmit}>
        <ApplyButton>
          <MdDone size={24} />
          APLICAR
        </ApplyButton>
        <span>NF-e</span>
        <label>
          Ambiente
          <SelectInput
            fullWidth
            options={ambienteOptions}
            value={ambienteOptions[data.nfe.ambiente - 1]}
            onChange={(newValue: any) => data.setAmbienteNfe(newValue.value)}
          />
        </label>
        <Certificate
          certificate={data.nfe.certificado}
          onChange={(file: File, password: string) => {
            data.setUploadCertificadoNfe(file, password);
          }}
          onRemove={() => {
            data.setRemoverCertificadoNfe(true);
          }}
        />
      </Form>
    </Container>
  );
});

export default Settings;
