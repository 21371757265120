import React from 'react';
import { MdErrorOutline } from 'react-icons/md';
import { Container, Message } from './styles';

const NotFound: React.FC = () => {
  return (
    <Container>
      <MdErrorOutline size={36} />
      <Message>Página não encontrada!</Message>
    </Container>
  );
};

export default NotFound;
