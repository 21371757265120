/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import Select, { Props as SelectProps } from 'react-select';
import colors from '../../styles/colors';

import { Container } from './styles';

interface Props extends SelectProps {
  label?: string;
  onChange: any;
  fullWidth: any;
}

const SelectInput: React.FC<Props> = ({ label, ...rest }) => {
  return (
    <Container>
      <label>
        {label}
        <Select
          // styles={customStyles}
          placeholder="Selecione..."
          noOptionsMessage={() => 'Sem opções disponíveis'}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary75: colors.primary,
              primary50: colors.primary,
              primary25: colors.primary,
              primary: colors.primary,
            },
          })}
          {...rest}
        />
      </label>
    </Container>
  );
};

export default SelectInput;
