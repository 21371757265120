import { makeAutoObservable } from 'mobx';
import ISettings, { INfeSettings } from '@interfaces/ISettings';
import EAmbiente from '@enums/EAmbiente';

class Settings implements ISettings {
  authenticated = false;

  loading = false;

  document: string;

  nfe: INfeSettings;

  constructor() {
    this.nfe = {
      ambiente: 1,
    };
    makeAutoObservable(this);
  }

  setAuthenticated(authenticated: boolean) {
    this.authenticated = authenticated;
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  setNfe(nfe: INfeSettings) {
    if (nfe != null) this.nfe = nfe;
  }

  setAmbienteNfe(ambiente: EAmbiente) {
    this.nfe.ambiente = ambiente;
  }

  setUploadCertificadoNfe(file: File, password: string) {
    if ((!file || file.size === 0) && !password) {
      this.nfe.uploadCertificado = undefined;
    } else {
      this.nfe.uploadCertificado = {
        file,
        password,
      };
    }
  }

  setRemoverCertificadoNfe(remover: boolean) {
    this.nfe.removeCertificado = remover;
  }
}

export default new Settings();
