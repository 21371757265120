import styled from 'styled-components';
import { darken } from 'polished';
import colors from '@styles/colors';

export const Container = styled.button`
  border: 0;
  background: ${(props) => (props.color ? props.color : colors.primary)};
  color: #f5f5f5;
  font-weight: bold;
  border-radius: 4px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  font-size: 14px;
  transition: background 0.2s;

  &:hover,
  &:focus {
    background: ${darken(0.03, colors.primary)};
    box-shadow: 0px 0px 1px ${colors.primaryDark};
  }

  svg {
    margin-right: 5px;
  }
`;
