import styled from 'styled-components';
import colors from '@styles/colors';
import Input from '@components/Input';

export const Container = styled.div`
  padding: 10px;
  width: 100%;
`;

export const Title = styled.div`
  color: ${colors.primary};
  margin: 5px 0 10px 5px;
`;

export const Selection = styled.div`
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #333;
  margin-bottom: 20px;

  & label {
    margin-top: 10px;
  }
`;

export const DateInput = styled(Input).attrs(() => ({
  type: 'date',
  max: '9999-12-31',
}))``;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 350px;
  margin-top: 20px;

  & button {
    width: 150px;
  }
`;

export const Table = styled.table`
  display: table;
  border-collapse: collapse;
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    display: block;
  }

  & th {
    padding: 8px;
    background: #777;
    color: #f5f5f5;
    font-weight: normal;
    border: 1px solid #666;
    margin: 0;
    /* width: calc(100% / 8); */
  }

  & td {
    padding: 5px;
    text-align: center;
    border: 1px solid #ccc;
  }
`;

export const ContainerStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Status = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 5px;
  border-radius: 30px;
  height: 30px;
  color: #f5f5f5;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
`;
