/* eslint-disable import/extensions */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { MdCancel } from 'react-icons/md';

import { Wrapper, Container, ExitButton } from './styles';

export default function Modal({ onCloseRequest, exitButton, children }) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  return (
    <Wrapper
      onClick={(e) => {
        if (onCloseRequest && e.target === e.currentTarget) {
          onCloseRequest();
        }
      }}
      onKeyDown={(e) => {
        if (onCloseRequest && e.key === 'Escape') onCloseRequest();
      }}
      tabIndex="0"
    >
      <Container autoOverflow={!exitButton}>
        {children}
        {exitButton && (
          <ExitButton onClick={() => onCloseRequest && onCloseRequest()}>
            <MdCancel color="#fafafa" />
          </ExitButton>
        )}
      </Container>
    </Wrapper>
  );
}
