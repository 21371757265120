import styled from 'styled-components';

export const Container = styled.div``;

export const CertificateView = styled.div`
  background: #eee;
  padding: 10px;
  margin: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
`;

export const CertificateData = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 100%;

  p {
    margin-bottom: 5px;
  }
`;
